class LighthouseScore extends HTMLElement {
    constructor() {
      super();
    }
  
    connectedCallback() {
      // Score nur einmal holen und clampen
      this._score = this.#parseScore();
  
      // Template rendern (Endfarbe)
      this.#renderTemplate(this._score);
  
      // Observer für Viewport-Sichtbarkeit
      this.#setupAnimation(this._score);
    }
  
    #parseScore() {
      const raw = parseInt(this.textContent.trim(), 10);
      return Math.min(Math.max(isNaN(raw) ? 0 : raw, 0), 100);
    }
  
    #getColorCategory(score) {
      if (score >= 90) return 'green';
      if (score >= 50) return 'orange';
      return 'red';
    }
  
    #renderTemplate(score) {
      const colorCategory = this.#getColorCategory(score);
  
      const classes = {
        background: {
          green: 'bg-green-100 dark:bg-primary-960',
          orange: 'bg-orange-100 dark:bg-orange-900',
          red: 'bg-red-100 dark:bg-red-900',
        }[colorCategory],
        stroke: {
          green: 'stroke-green-600',
          orange: 'stroke-orange-600 dark:stroke-orange-400',
          red: 'stroke-red-600 dark:stroke-red-400',
        }[colorCategory],
        text: {
          green: 'text-green-700',
          orange: 'text-orange-700 dark:text-orange-300',
          red: 'text-red-700 dark:text-red-300',
        }[colorCategory],
      };
  
      this.innerHTML = `
        <div class="flex flex-col items-center">
          <div class="relative w-32 h-32">
            <div class="absolute inset-0 flex items-center justify-center">
              <div class="${classes.background} w-[126px] h-[126px] rounded-full flex items-center justify-center">
                <svg class="w-full h-full" viewBox="0 0 128 128">
                  <circle
                    class="circle-progress ${classes.stroke}"
                    fill="none"
                    stroke-width="10"
                    stroke-linecap="round"
                    cx="64"
                    cy="64"
                    r="59"
                    transform="rotate(-90 64 64)" 
                  />
                </svg>
                <span class="absolute text-[45px] ${classes.text} font-mono"></span>
              </div>
            </div>
          </div>
        </div>
      `;
    }
  
    #setupAnimation(score) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          this.#animateValue(score);
          observer.disconnect();
        }
      }, { threshold: 0.5 });
  
      observer.observe(this);
    }
  
    #animateValue(target) {
      const circumference = 2 * Math.PI * 59; // r=59
      const progressEl = this.querySelector('.circle-progress');
      const valueEl = this.querySelector('span');
  
      progressEl.style.strokeDasharray = circumference;
      progressEl.style.strokeDashoffset = circumference;
  
      const startTime = performance.now();
      const duration = 1500; // ms
  
      const update = (timestamp) => {
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const current = Math.floor(progress * target);
  
        valueEl.textContent = current;
        progressEl.style.strokeDashoffset = circumference * (1 - current / 100);
  
        if (progress < 1) {
          requestAnimationFrame(update);
        } else {
          valueEl.textContent = target;
          progressEl.style.strokeDashoffset = circumference * (1 - target / 100);
        }
      };
  
      requestAnimationFrame(update);
    }
  }
  
  customElements.define('lighthouse-score', LighthouseScore);
  